.home-container {
	display: flex;
	flex-direction: column;
	/* gap: 25px; */
	/* max-width: 82%; */
}

.home-card {
	min-width: 300px;
	padding: 24px !important;
	border-radius: 8px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .08);
	overflow-x: auto;
	background-color: white;
	border: 1px solid #d6d6e4;
	/* margin-bottom: 16px; */
}

.clocking-status {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	padding: 0 16px;
	border-radius: 20px;
	border: 1px solid #d1d2e1;
	font-size: 12px;
	color: #363958;
	font-weight: 700;
	margin: 16px !important;
}

.clocking-status-green,
.clocking-status-red {
	min-width: 10px;
	min-height: 10px;
	border-radius: 50%;
	margin-right: 16px;
	display: inline-block;
}

.clocking-status-green {
	background: #1bc181;
}

.clocking-status-red {
	background: #ff5464;
}

@media screen and (min-width: 1440px) {
	.home-container {
		max-width: 100%;
	}
}
/* 
@media screen and (max-width: 1440px) {
	.home-container {
		max-width: 80%;
	}
}

@media screen and (max-width: 1200px) {
	.home-container {
		max-width: 71%;
	}
} */

@media screen and (max-width: 960px) {
	.home-container {
		max-width: 100%;
		padding: 0px !important;
	}
}