.navbar-menu.active {
    border-left-width: 4px !important;
    border-color: #ff5464 !important;
}

.navbar-menu {
    width: 100%;
}

.navbar-menu:hover {
    background: #e8e8eb;
    cursor: pointer;
    transition: all .5s;
}

.chakra-slide {
    background: none !important;
    max-width: 15rem !important;
}

.mobile-menu-icon {
    font-size: 1.5rem;
}

.mobile-menu-icon:hover {
    transition: all .5s;
    transform: rotate(15deg);

}

.app_header {
    background: white;
    margin-top: 0px;
    position: fixed;
    left: 15rem;
    right: 0px;
    top: 0px;
    z-index: 12;
    height: 80px;
    border-bottom: 1px solid #d1d2e1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chakra-menu__menuitem:focus {
    background: none !important;
}

.chakra-avatar__initials {
    color: #fff;
    font-size: 0.9em !important;
    font-weight: 700;
}