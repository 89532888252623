.admin-box-container {
    border: 1px solid #d1d2e1;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
    border-radius: 8px;
    color: #545866;
    background: #FFF;
}

.admin-header-button {
    background-color: #ff5464 !important;
    color: #FFF;
    font-weight: bold !important;
    border-radius: 50px !important;
}

.admin-header-button:hover {
    opacity: 0.9;
}

.admin-table-td {
    padding: 12px 24px !important;
    font-size: 12px;
}

.firstname_field:focus,
.lastname_field:focus,
.email_field:focus,
.password_field:focus,
.conf_password_field:focus,
.search_field:focus-visible {
    /* border-radius: 0 !important; */
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-color: #ff5464 !important;
    box-shadow: 0px 1px 0px 0px #ff5464 !important;
}

.firstname_label,
.lastname_label,
.email_label,
.password_label,
.conf_password_label,
.search-label {
    position: absolute;
    top: 8px;
    left: 10px;
    z-index: 3;
    font-size: 14px;
    cursor: text;
    color: #8186aa;
    transition: top .3s;
}


.firstname_field:focus+.firstname_label,
.lastname_field:focus~.lastname_label,
.email_field:focus~.email_label,
.password_field:focus~.password_label,
.conf_password_field:focus~.conf_password_label,
.search_field:focus~.search-label,
.firstname_field:not(:placeholder-shown).firstname_field:not(:focus)~.firstname_label,
.lastname_field:not(:placeholder-shown).lastname_field:not(:focus)~.lastname_label,
.email_field:not(:placeholder-shown).email_field:not(:focus)~.email_label,
.password_field:not(:placeholder-shown).password_field:not(:focus)~.password_label,
.conf_password_field:not(:placeholder-shown).conf_password_field:not(:focus)~.conf_password_label,
.search_field:not(:placeholder-shown).search_field:not(:focus)~.search-label {
    top: -11px !important;
    color: #ff5464;
    font-size: 12px;
}

.license_label {
    position: absolute;
    bottom: 5px;
    left: 12px;
    opacity: 0.6 !important;
    cursor: text;
    color: #545866 !important;
}


.license_field:not(:placeholder-shown).license_field:not(:focus)~.license_label,
.license_field:focus~.license_label {
    position: absolute;
    bottom: 2rem;
    font-size: small;
    color: #DE4433 !important;
    background-color: #fff;
    opacity: 1 !important;
    z-index: 124;
}


.license-item ul {
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
    width: 24rem;
    z-index: 500;
    color: #545866;
}

/* .filters-list ul li {
    display: inline-block;
    color: #000;
} */

.license-item a {
    display: block;
    color: #000;
    font-size: 1rem;
    line-height: 60px;
    text-decoration: none;
}

.license-item ul ul li:hover {
    background-color: #eee;
    cursor: pointer;
}

.license-item ul ul li {
    text-transform: uppercase;
}

/* Hide Dropdowns by Default */
.license-item ul ul {
    display: none;
    position: fixed;
    width: 22rem;
    z-index: 2000;
    max-height: 19rem;
    overflow-y: scroll;
    background: #fff;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, .16) !important;
    border-radius: 10px;
    color: #111;
}

/* Display Dropdowns on Hover */
/* .filters-list ul li:hover>ul, */

.license-item ul li:focus-within>ul {
    display: inherit !important;
}


.license-item ul ul .list-style {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    padding: 12px 20px;
    width: 100%;
}

/* Fisrt Tier Dropdown */
.license-item ul ul li {
    /* width: max-content; */
    /* width: 27rem; */
    float: none;
    display: list-item;
    position: relative;
}

/* .modal-close-btn:hover { */
.down_icon:hover,
.icon-button:hover {
    background: none !important;
    transition: all .5s;
    transform: rotate(15deg);
}

.icon-button svg path {
    stroke: #8186aa;
}

.btn-add-license {
    display: inline-flex;
    justify-content: flex-start;
    align-content: flex-start;
    margin: 12px 0px;
    border: 1px solid rgb(0 0 0 / 10%);
    background: #FFF !important;
    color: #111;
}

.btn-add-license:hover {
    background: #eee !important;
}

.modalCloseButton{
    top: 2.5rem !important;
    right: 2rem !important;
}
