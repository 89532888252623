@import "assets/styles/fullCalendar";

.fc-icon {
    display: none;
}

.line {
    width: 70px;
    border-bottom: 1px solid $opus-border-color;
    transform: translateY(-50%);
    height: 1px;
    align-self: center;
    margin-left: 16px;
    opacity: 0.7;
}

.fc-day-today {
    color: #fe5464;
}

.eventContent {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;

    .actions {
        display: none;

        button {
            height: 100%;
        }
    }

    .title {
        display: flex;
        align-items: center;
        white-space: pre;
        word-break: keep-all;
    }

    &.chantier {
        .actions {
            button {
                color: #000;   
            }
        }

        .title {
            min-width: 240px;
            justify-content: flex-start;
            align-items: center;

            .naming {
                min-width: 120px;
            }
        }
    }
}

.eventContent:hover {
    .actions {
        display: flex;
    }
    
    .title {
        display: none;
    }
}

.planningEmployee {
    .fc-timeline-event-harness {
        transform: translate(0, 30%);
    }
}

.planningEmployeeChantier {
    .fc-timeline-event-harness {
        transform: translate(0, 0);
    }

    .eventContent {
        height: 50px;
        width: 100%;
    }
}

.sidebartab {
    width: 80px;
    font-size: 0.8rem;
    font-weight: normal;
    color: #8186aa;

    &[aria-selected=true]{
        border-color: #ff5464;
        color: #000;
    }
}

.progressLoader {
    position: absolute;
    width: 100%;
    left: 0;
    margin: 0;
}

.fc .fc-scrollgrid {
    border-left-width: 0;
}