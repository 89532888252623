.stat-container {
  max-width: 100% !important;
  width: 85% !important;
  margin: 0px;
  margin-left: 14rem !important;
  padding-top: 0px;
  padding-bottom: 0px;
}

@media only screen and (max-width: 62em) {
  .stat-container {
    margin-left: 0px !important;
  }
}

.about-div {
  background-color: white;
  border: 10 solid grey;
  box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 0.1);
  color: black;
  border-radius: 10px;
  box-sizing: border-box;
  max-width: 100%;
  margin-bottom: 30px;
  text-align: left;
  position: relative;
}


.about-form-container {
  margin: 15px 15px;
}

.about-text {
  color: #545866;
}

.input-endPeriode:focus-visible,
.input-startPeriode:focus-visible {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-color: #ff5464 !important;
  box-shadow: 0px 1px 0px 0px #ff5464 !important;
}

.input-endPeriode:focus+.edit-IconDate,
.startPeriode:focus+.edit-IconDate {
  color: #ff5464 !important;
}

.horaires-table {
  padding: 10px;
  border: 1px solid rgb(216, 216, 216);
  border-radius: 30px !important;
}

.horaires-table2 {
  width: 32rem !important;
  padding: 10px;
  border: 1px solid rgb(216, 216, 216);
  border-radius: 20px !important;
}

.icon:hover {
  cursor: pointer;
}

.statistic-global-row {
  flex-direction: row !important;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: 24px;
}

.select-sites:focus-visible{
  border-color: #ff5464 !important;
  box-shadow: 0px 1px 0px 0px #ff5464 !important;
}

.select-sites .option-sites:checked{
  background: #ffe4e7 !important;
}

.statistic-input-floating-text {
  position: absolute;
  left: 13px;
  font-size: 12px !important;
  transform: translateY(-10px);
  color: #8186AA;
  z-index: 2;
}

.statistic-global-totals-box {
  padding: 0.75rem;
  color: black;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 700 !important;
  border: 1px solid #d1d2e1;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
  border-radius: 8px;
  background: #fff;
}

.statistic-global-budget-box {
  padding: 16px 65px 16px 16px;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 700 !important;
  border: 1px solid #d1d2e1;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
  border-radius: 8px;
  background: #fff;

}

.statistic-section-title-input {
  min-width: 200px !important;
  cursor: pointer;
}

.validate-data-btn {
  background: #ff5464 !important;
  color: #fff;
  border-radius: 40px !important;
  padding: 0px 30px !important;
}

.validate-data-btn:active {
  background: none !important;
}

.validate-data-btn:hover {
  background: #ff5464 !important;
}

.validate-data-btn:focus-visible {
  box-shadow: none !important;
}


.statistic-table Td {
  font-family: Open Sans, sans-serif !important;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 400 !important;
  padding: 12px !important;
}

.statistic-table Tbody Tr:last-child Td {
  font-weight: 700 !important;
  color: black !important;
}

@media screen and (max-width: 960px) {
  .statistic-global-row {
    flex-direction: column !important;
    align-items: flex-start;
    gap: 20px;
  }
}