$opus-primary: #FF5464;
$opus-primary-rgb: rgb(255, 84, 100);
$opus-secondary: #FCC35B;
$opus-secondary-variant: rgba(252, 196, 91, 0.603);
$opus-third: #1BC181;
$opus-third-variant: #5FD3AC73;
$opus-fourth: #5C83D7;

$opus-background: #f1f1f4;
$opus-border-color: #d1d2e1;

$opus-font-color-1: #363958;
$opus-font-color-2: #8186aa;

// Font-size
$opus-font-size-1: 1.25rem; //20px
$opus-font-size-1b: 1rem; //16px
$opus-font-size-2: 0.875rem; // 14px
$opus-font-size-3: 0.75rem; // 12px
$opus-font-size-4: 0.625rem; // 10px

// Shadow
$opus-shadow-1: 0 1px 0 0 $opus-border-color;
$opus-shadow-2: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
$opus-shadow-elevation: 0 8px 16px 0 rgba(0, 0, 0, 0.16);
$opus-shadow-elevation-2: linear-gradient(to bottom, rgba(0, 0, 0, 0.04) -23%, rgba(0, 0, 0, 0));

// Font
$opus-font-family: "Open Sans", sans-serif;

//input
$opus-height-input: 40px;
$opus-height-input-with-hint: calc(#{$opus-height-input} + 20px);

$opus-height-input-large: 48px;
$opus-height-input-with-hint-large: calc(#{$opus-height-input-large} + 20px);

.fc-theme-standard td, .fc-theme-standard th {
    //border-right: none;
    //border-left: none;

    // initial
    border: none;
}

.fc-datagrid-cell.fc-resource, .fc-datagrid-cell.fc-resource-group.fc-cell-shaded {
    //border: none;
    //background: none !important;

    // initial
    border: none;
    background: none !important;
}

.fc .fc-cell-shaded, .fc .fc-day-disabled, .fc .fc-resource-timeline .fc-resource-group:not([rowspan]) {
    background: $opus-background;

    //initial
    //  background: none;
}

.fc .fc-cell-shaded, .fc .fc-day-disabled {
    border-right: 1px solid #f1f1f4 !important;
}

.fc .fc-datagrid-cell-cushion {
    width: 100%;
    height: 100%;
}

.fc .fc-datagrid-expander-placeholder {
    display: none;
}

.btn-plus {
    width: 26px !important;
    min-width: 26px !important;
    height: 24px !important;
    position: absolute;
    left: 100%;
    top: -1px;
    margin-left: 8px;
    border-radius: 4px !important;
    z-index: 1;
}

.fc-custom-slot-label { // Custom slot link
    display: grid;
    padding: 12px;
    color: $opus-font-color-1;
}

.fc-timeline-slot-frame {
    position: relative;
}

.fc-timeline-slot-frame:has(.mbborder) {
    &:after {
        height: 40px;
        display: block;
        width: 1px;
        background: #e0e0e0;
        content: '';
        position: absolute;
        right: 0;
    }
}

.fc .fc-timeline-header-row:last-child .fc-timeline-slot-frame {
    overflow: visible;
}

.fc-day {
    color: #8186aa !important;
}

.fc-day-today { // Current day
    color: $opus-primary !important;
}

.fc-h-event:not(.fc-event-selected) .fc-event-resizer {
    width: 18px !important;
}

.fc-timeline-lane.fc-resource-group.fc-cell-shaded {
    height: 40px;
}

.fc-event {
    cursor: pointer;
}

.fc-scroller-harness { // first TD [Semaine W - YYYY]

    border-bottom: 1px solid #f1f1f4 !important;

    .fc-datagrid-header {

        .fc-datagrid-cell-cushion {
            width: 100%;
        }
    }
}

.opus-calendar {
    .line {
        border: 0.5px solid $opus-border-color;
        width: 70px;
        height: 1px;
        align-self: center;
        margin-left: 16px;
        opacity: 0.7;
    }

    .fc-scrollgrid { // table

        thead {


            .fc-resource-timeline-divider { // Divider

            }

            .fc-scroller-harness { // Slot table

                .fc-timeline-header {

                    table {

                        .fc-timeline-header-row { // Slot row

                            .fc-timeline-slot { // Slot item

                                width: 100px;

                                &.fc-day-past { // Day passed

                                }


                                &.fc-day-future { // Futur day

                                }

                                .fc-timeline-slot-frame {
                                    height: auto !important;


                                    // End slot item frame
                                }

                                // End slot item
                            }

                            // End slot row
                        }
                    }
                }


                // End slot Table
            }

            // End Thead
        }


        tbody {
            .fc-scroller-harness.fc-scroller-harness-liquid {
                table {
                    .fc-datagrid-cell {
                        .fc-datagrid-cell-main {
                            //   display: none;
                        }

                        &.fc-resource {
                            .fc-icon {
                                display: none;
                            }

                            .fc-datagrid-expander {
                                display: none;
                            }
                        }
                    }
                }


            }

            .fc-timeline-body {
                .fc-bg-event {
                    opacity: 1 !important;
                }

                //  overflow-y: hidden;
                background: linear-gradient(90deg, rgba(2,0,36,0.143855) -5%, rgba(0, 212, 255, 0) 3%, rgba(0, 0, 0, 0) 0%);

                .fc-timeline-slots {
                    table {
                        td.fc-timeline-slot {
                            border-right: 1px solid #f1f1f4 !important;
                        }
                    }
                }

                .fc-scrollgrid-sync-table {
                    .fc-timeline-event-harness {
                        transform: translate(0, 10%);
                        //z-index: 2;
                        .fc-timeline-event {
                            border-radius: 4px;
                        }
                    }
                }
            }
        }

        // End table
    }

    // End opus calendar
}
