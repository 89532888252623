@import "assets/styles/fullCalendar";

.fc-icon {
    display: none;
}

.line {
    width: 70px;
    border-bottom: 1px solid $opus-border-color;
    transform: translateY(-50%);
    height: 1px;
    align-self: center;
    margin-left: 16px;
    opacity: 0.7;
}

.fc-day-today {
    color: #fe5464;
}

.intervention-tbody {
    td {
        border-bottom: none;
        padding: 5px 0;
    }
}

.intervention-title {
    font-size: 12px;
    font-weight: 700;
}

.intervention-chapter {
    color: #646574;
    font-size: 11px;
    font-weight: 400;

    td {
        border-bottom: none;
    }

    td:first-of-type {
        padding-left: 45px;
    }
}
