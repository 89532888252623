.about-div-notif {
  background-color: white;
  border: 10 solid grey;
  box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 0.1);
  color: black;
  border-radius: 10px;
  box-sizing: border-box;
  max-width: 100%;
  /* margin: 0px 30px; */
  margin-bottom: 30px;
  text-align: left;
}

.notification-container {
  max-width: 100% !important;
  width: 82% !important;
  margin-inline-start: 15rem !important;
  color: rgba(0, 0, 0, 0.87);
}

.about-form-container {
  margin: 15px 15px;
}

.about-text {
  color: #545866;
}

.hstack-head-container {
  justify-content: space-between;
  padding: 30px 30px 10px;
  font-size: 14px;
  color: #363958;
}

.hstack-body-container {
  justify-content: space-between;
  padding: 0px 30px;
}

.about-hr {
  width: 59.5rem !important;
  margin: 2px 0px 6px 28px;
}

.arrow-right-icon:hover {
  background: none !important;
  cursor: default !important;
}

.arrow-right-icon:active {
  background: none !important;
}

.notification-check_btn:hover {
  background: #e2e2e8 !important;
}

.alert-icon:hover,
.check-icon:hover {
  transition: all .5s;
  transform: rotate(15deg);
}

.alert-notification {
  position: fixed !important;
  z-index: 999;
  bottom: 30px;
  left: 25px;
}

.box {
  display: flex;
  flex-direction: row;
  max-width: 540px;
  background-color: #fff;
  padding: 20px 13px;
  margin-top: 10px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12) !important;
  border-radius: 5px;
  max-height: fit-content;
}

.alert-button {
  font-size: 12px !important;
  font-weight: 700;
  padding: 0px 18px;
  height: 25px !important;
  color: #363956;
  background: #f1f1f4 !important;
  letter-spacing: normal;
}

.alert-button:hover {
  background: #e2e2e8 !important;
}

.alert-icon {
  color: #de4433 !important;
  margin-right: 1rem !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.info-icon {
  color: #de4 !important;
  margin-right: 1rem !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.notif-content-box {
  display: flex !important;
}

/* Responsives styles code*/

@media screen and (max-width: 62em) {
  .notification-container {
    max-width: 100% !important;
    width: 100% !important;
    margin-inline-start: 0rem !important;
  }
}

@media screen and (max-width:425px) {
  .hstack-head-container {
    display: flex !important;
    flex-direction: column !important;
  }

  .hstack-body-container {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .update_button {
    margin-top: 1rem !important;
    margin-inline-start: 0rem !important;
  }

  .notif-content-box {
    display: -webkit-box !important;
  }

  /*Alert Notification css*/

  .alert-button {
    width: max-content !important;
    margin-bottom: 0.5rem;
  }

  .box {
    max-width: 19rem !important;
    padding: 8px 10px !important;
  }

  .button-box {
    display: flex !important;
    flex-direction: column !important;
  }

  .button-box button {
    margin-left: 0rem !important;
  }
}