.setting-container {
  max-width: 100% !important;
  width: 87% !important;
  margin-left: 14rem !important;
}

.div-setting {
  background-color: white;
  box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-width: 100%;
  margin-bottom: 30px;
}

.about-container-btn {
  display: flex;
  margin-bottom: 30px;
}

.about-form-container {
  margin: 15px 15px;
}

.about-text {
  color: #545866;
}

.add_new-label_field:focus-visible,
.input_coef:focus-visible,
.number_day_input {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-color: #ff5464 !important;
  box-shadow: 0px 1px 0px 0px #ff5464 !important;
  caret-color: #FF5464;
}

.input_coef,
.add_new-label_field {
  padding: 24px 68px 24px 10px !important;
}

/*Input Placeholder Animation on Focus */

.add_new-label {
  position: absolute;
  bottom: 5px;
  left: 12px;
  cursor: text;
  font-size: .875rem !important;
  cursor: text;
  color: #8186aa;
  transition: top .3s;
}

.add_new-label_field:focus~.add_new-label {
  color: #ff5464 !important;
}


.add_new-label_field:not(:placeholder-shown).add_new-label_field:not(:focus)~.add_new-label,
.add_new-label_field:focus~.add_new-label {
  position: absolute;
  bottom: 2rem;
  font-size: small;
  color: #8186aa;
  /* background-color: #ffffff4a; */
  opacity: 1 !important;
  z-index: 4;
}

.add_button:hover {
  background: none !important;
  transition: all .5s;
  transform: rotate(15deg);
}


.input-lib:focus-visible {
  border-color: #FF5464 !important;
  box-shadow: 0 0 0 1px #ff5464 !important;
}

.input-lib {
  color: rgba(0, 0, 0, .38) !important;
  padding: 24px 68px 24px 10px !important;
}

.edit-IconDate:focus-visible {
  color: #ff5464 !important;
}

.modal-content {
  padding: 24px;
  margin-top: 2rem !important;
  max-height: calc(100% - 3.5rem) !important;

}

.icon:hover {
  cursor: pointer;
}

.multiselect-container div {
  width: 100%;
}

.form-coef {
  width: auto !important;
}


.coef_label {
  position: absolute;
  top: -11px;
  left: 12px;
  z-index: 3;
  color: #8186AA;
  font-size: 12px !important;
}

.lib_label {
  position: absolute;
  top: -14px;
  left: 12px;
  z-index: 3;
  color: #8186AA;
  font-size: 16px !important;
}

.setting-time-picker {
  /* min-width: 12.5rem; */
  padding: 24px 65px 24px 10px !important;
  height: 50px;
  font-size: 1rem;
  cursor: pointer;
}

.monday_label {
  position: absolute;
  top: -11px;
  left: 12px;
  z-index: 3;
  color: #363958 !important;
  font-size: 12px !important;
  background-color: white;

}

.libelle:focus-visible {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-color: #ff5464 !important;
  box-shadow: 0px 1px 0px 0px #ff5464 !important;
}


.libelle_label {
  position: absolute;
  top: 12px;
  left: 10px;
  z-index: 3;
  opacity: 0.5 !important;
  cursor: text;
  transition: top .3s;
}

.libelle:focus+.libelle_label,
.libelle:not(:placeholder-shown).libelle:not(:focus)~.libelle_label {
  position: absolute;
  top: -10px;
  font-size: small;
  color: #DE4433;
  opacity: 1 !important;
  z-index: 12;
}



.number_day_input_label {
  position: absolute;
  top: -11px;
  left: 12px;
  z-index: 3;
  /* opacity: 0.5 !important; */
  color: #8186AA;
  font-size: 14px !important;
}

.number-day-input-field:focus-visible {
  outline: none;
  border-bottom-right-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
  border-color: #ff5464 !important;
  box-shadow: 0px 1px 0px 0px #ff5464 !important;
}


.number-day-input-field {
  padding: 0px 48px 0px 12px !important;
}

.number-input-stepper svg {
  width: 10px;
  height: 10px;
}

@media screen and (max-width: 960px) {
  .grid-box-container {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr) !important;
    gap: 12 !important;
  }
}