.input-search {
    border-radius: 8px !important;
    background-color: #eee !important;
}

.input-search:focus-visible {
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-color: #ff5464 !important;
    box-shadow: 0px 1px 0px 0px #ff5464 !important;
}

.container-table {
    border-top-width: 1px;
    border-top-color: #eee;
    position: relative;
}

.container-table th {
    font-size: 10px;
}

.container-table .container-thead tr {
    border-bottom-width: 1px !important;
    border-top-width: 1px !important;
}

.container-table .container-tbody tr {
    border-bottom: hidden;
}

.container-tbody td {
    font-size: 12px;
}

.container-text {
    width: max-content;
}

.container-tbody tr:hover {
    background: #e8e8eb;
}

.container-thead th {
    font-size: .75rem;
    height: 48px;
}

@media screen and (max-width: 1024px) {
    .app-component-section {
        max-width: 100% !important;
    }
}

@media screen and (max-width: 425px) {
    .app-component-section {
        max-width: 100% !important;
    }

    .container-table thead {
        position: absolute;
        width: 1px;
        height: 1px;
        overflow: hidden;
    }

    .container-table tr {
        display: block;
        margin-bottom: .625em;
        border-width: 1px;
    }

    .container-table td {
        border-bottom: 1px solid #ddd;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .container-table td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }
}

@media screen and (min-width: 1800px) {
    .modal-content {
        width: 25% !important;
    }
}