@import "assets/styles/fullCalendar";

.eventContent {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;

    .actions {
        display: none;

        button {
            height: 100%;
        }
    }

    .title {
        display: flex;
        align-items: center;
    }
}

.eventContent:hover {
    .actions {
        display: flex;
    }
    
    .title {
        display: none;
    }
}

.fc-timeline-event-harness {
    transform: translate(0, 10%);
}


.engins {
    .fc-timeline-event-harness {
        transform: translate(0, 50%);
    }   
}

.fc-timeline-event {
    border-radius: 4px;
}

.fc-day-today {
    color: #fe5464;
}

.fc .fc-bg-event {
    opacity: 1;
}