html {
  /* zoom: 70%; */
  /* transform: scale(0.5);
  transform-origin: 0 0;
  width: 200%;
  height: 200%; */
}

body {
  width: 100%;
  height: 100%;
  position: absolute !important;
  box-sizing: border-box;
}

#root {
  width: 100%;
  height: 100%;
  position: absolute !important;
  /* overflow: hidden; */
}

input, select, textarea {
  border-radius: 0.375rem !important;
  background-color: #eee;
}

input, textarea {
  padding: 24px 10px !important;
}

[id^=rc-editable-input] {
  padding: 4px 10% 3px !important;
}

select {
  height: 49px !important;
  padding-left: 10px !important;
}

select:hover {
  cursor: pointer;
}

input:not(input[type='file']):focus-visible,
textarea:focus-visible,
select:focus-visible,
div[class*='-control']:focus-within {
  /*border-bottom-right-radius: 0px !important;*/
  /*border-bottom-left-radius: 0px !important;*/
  border-color: #ff5464 !important;
  box-shadow: none/*0px 1px 0px 0px #ff5464 */!important;
}

.label-form {
  position: absolute;
  top: 13px;
  left: 10px;
  opacity: 0.5 !important;
  cursor: text;
  transition: top .3s;
  padding: 0px 5px;
  font-size: .875rem!important;
  z-index: -1;
}

.label-up {
  position: absolute;
  top: -10px;
  left: 10px;
  opacity: 0.5 !important;
  cursor: text;
  transition: top .3s;
  padding: 0px 5px;
  width: max-content;
  font-size: .875rem!important;
}

.label-up,
input:not(input[type='file']):focus+.label-form,
textarea:focus+.label-form,
select:focus+.label-form,
input:not([type='file']):not(:placeholder-shown):not(:focus) ~.label-form,
textarea:not(:placeholder-shown):not(:focus) ~.label-form,
select:not(:placeholder-shown):not(:focus) ~.label-form {
    position: absolute;
    top: -10px;
    /*color: #DE4433;*/
    color: #8186aa;
    opacity: 1 !important;
    z-index: 1;
    background-color: #fff;
}

.chakra-checkbox__control:focus-visible {
  box-shadow: none !important;
}

.chakra-checkbox__control[data-indeterminate],
.chakra-checkbox__control[data-checked] {
  padding: 0 !important;
  background-color: #ff5464 !important;
  border-color: #ff5464 !important;
}

.chakra-button[data-variant=solid] {
  background: #ff5464 !important;
  border: none;
  color: #fff !important;
}

.chakra-button[data-variant=solid]:hover,
.chakra-button[data-variant=outline]:hover {
  opacity: 0.8;
}

.chakra-button[data-variant=outline] {
  background: #fff !important;
  border: 1px solid #ff5464 !important;
  color: #ff5464 !important;
}

.chakra-button[data-variant=outline-white] {
  background: #fff !important;
  border: 1px solid #d1d2e1 !important;
  color: #363958 !important;
}

.chakra-button[data-variant=outline-white]:hover {
  background-color: #efeff2 !important;
}


.chakra-button[data-variant=solid],
.chakra-button[data-variant=outline] {
  border-radius: 25px;
  font-weight: 800;
  padding: 0px 25px;
}

.chakra-button[data-variant=outline-white] {
  border-radius: 5px;
  font-weight: 800;
  padding: 0px 25px;
}

.chakra-modal__close-btn:hover {
  background: none !important;
  transition: all .5s;
  transform: rotate(15deg);
}

input[id*='react-select'] {
  padding: 0 !important;
  border: none !important;
  box-shadow: none !important;
  height: 35px;
}

div[id*='listbox'] {
  z-index: 10
}

.pagination-button {
  text-align: center;
  align-items: center;
  margin-left: 40%;
}


.pagination {
  display: flex;
  list-style: none;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.pagination li.previous a,
.pagination li.next a,
.pagination li.break a {
  border-color: #ff5464;
}

.pagination li {
  display: inline-block;
  margin: 3px;
}

.pagination li a {
  cursor: pointer;
  border-radius: 50%;
  height: 34px;
  min-width: 34px;
  width: auto;
  font-size: 1rem;
  display: inline-block;
  text-align: center;
  padding-top: 5px;

}

.pagination li.active a {
  background-color: #ff5464;
  border-color: #ff5464;
  color: white;
}

.pagination li.disabled a {
  color: grey;
}

.pagination li.disable,
.pagination li.disabled a {
  cursor: default;
}

.PhoneInputInput, .PhoneInputCountry {
  background: #fff;
  border: 1px solid #edf1f5;
  height: 50px;
}

input[type='file'] {
  opacity: 0 !important;
  z-index: 3 !important;
  cursor: pointer !important;
}

.chakra-input__group {
  z-index: 0;
}

.chakra-input__group:has(input[type='file']),
.file-result {
  border: 1px dashed #8186AA !important;
  border-radius: 8px;
  min-height: 50px !important;
}

.file-result.multiple {
  border: 1px solid #8186AA !important;
}

.chakra-input__group:has(input[type='file']){
  height: 50px;
}

.chakra-avatar:not(.topbar-avatar) {
  background: #f1f1f4 !important;
}

.chakra-avatar:not(.topbar-avatar) > .chakra-avatar__initials {
  color: black;
}

.reload_progress {
  position: absolute !important;
  top: 43px;
  right: 0px;
  left: 0px;
}

.css-h5ends {
  background-image: linear-gradient(to right, transparent 0%, #ff5464 50%, transparent 100%) !important;
  animation: animation-11lmxjq 1s cubic-bezier(0.25, 0.1, 0.64, 0.9) infinite normal none running !important;
}

.chakra-modal__overlay {
  width: 100% !important;
  height: 100% !important;
}

.chakra-modal__content-container {
  width: 100% !important;
  height: 100% !important;
}

.chakra-menu__menu-list {
  /*transform: translateX(90%) !important;*/
}

.chakra-modal__content {
  transform: none !important;
}

.chakra-slider__track {
  background: #d2d2d6 !important;
}

.chakra-slider__thumb {
  background: #fe5464 !important;
}


*[draggable] {
  cursor: grab;
}

.MuiInputBase-input {
  padding: 14px 10px !important;
}

.MuiPickersPopper-root {
  z-index: 1000000;
}

.rowSearch {
    .label-form {
      top: 15px;
    }
}
